<template>
    <MenuComponent menu-selector="#kt-search-menu">
        <template v-slot:toggle>
            <!--begin::Search-->
            <div
                id="kt_header_search"
                class="d-flex align-items-stretch"
                data-kt-menu-target="#kt-search-menu"
                data-kt-menu-trigger="click"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
                data-kt-menu-flip="bottom"
            >
                <!--begin::Search toggle-->
                <div class="d-flex align-items-center" id="kt_header_search_toggle">
                    <div class="btn btn-icon btn-active-light-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/general/gen021.svg"/>
                        </span>
                    </div>
                </div>
                <!--end::Search toggle-->
            </div>
            <!--end::Search-->
        </template>
        <template v-slot:content>
            <!--begin::Menu-->
            <div class="menu menu-sub menu-sub-dropdown menu-column p-7 w-325px w-md-375px" data-kt-menu="true" id="kt-search-menu">
                <!--begin::Wrapper-->
                <div>
                    <!--begin::Form-->
                    <form class="w-100 position-relative mb-3" autocomplete="off" onsubmit="return false">
                        <!--begin::Icon-->
                        <span class="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0">
                            <inline-svg src="/media/icons/duotune/general/gen021.svg"/>
                        </span>
                        <!--end::Icon-->

                        <!--begin::Input-->
                        <input ref="inputRef" v-model="search" type="text" class="form-control form-control-flush ps-10"  :placeholder="$t('common.search')"/>
                        <!--end::Input-->

                        <!--begin::Spinner-->
                        <!--<span v-if="loading" class="position-absolute top-50 end-0 translate-middle-y lh-0 me-1">
                            <span class="spinner-border h-15px w-15px align-middle text-gray-400"></span>
                        </span>-->
                        <!--end::Spinner-->
                    </form>
                    <!--end::Form-->

                    <!--begin::Separator-->
                    <div class="separator border-gray-200 mb-6"></div>
                    <!--end::Separator-->
                    <!--<Results v-if="state === 'results'"></Results>
                    <Main v-else-if="state === 'main'"></Main>
                    <Empty v-else-if="state === 'empty'"></Empty>-->
                </div>
                <!--end::Wrapper-->

                <div class="d-flex justify-content-end">
                    <button class="btn btn-sm fw-bolder btn-primary w-100" @click="searchEvent">
                        {{ $t('common.search') }}
                    </button>
                </div>
            </div>
            <!--end::Menu-->
        </template>
    </MenuComponent>
</template>

<script>
import Results from "@/layouts/header/partials/search/Results.vue";
import Main from "@/layouts/header/partials/search/Main.vue";
import Empty from "@/layouts/header/partials/search/Empty.vue";
import MenuComponent from "@/components/menu/MenuComponent.vue";

export default {
    name: "kt-search",
    components: {
        Results,
        Main,
        Empty,
        MenuComponent,
    },
    data(){
        return {
            search: "",
            state: "main",
            loading: false
        }
    },
    methods:{
        load(current) {
            this.loading = true;
            setTimeout(() => {
                this.state = current;
                this.loading = false;
            }, 1000);
        },
        reset(){
            this.search = "";
            this.state = "main";
        },
        setState(curr) {
            this.state = curr;
        },
        searchEvent(){
            this.$router.push({
                path: "/video"
            });

            setTimeout(() => {
                this.eventBus.$emit('searchEvent', this.cloneData(this.search));
            }, 500)
        }
    }
};
</script>
